import {Layout} from "antd";
import React, {FC, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import webSocket from "../services/webSocket";
import notifi from "../components/notifi";
import {isNotificationLevel} from "../types/notificationsType";
import AppFooter from "../layout/appFooter";
import AppHeader from "../layout/appHeader";
import AppSider from "../layout/appSider";
import {getPath, isFullscreenRoute} from "../routes/appRoutes";
import UserStore from "../contexts/userStore";
import {useInterval} from "../hooks/useInterval";
import AppExtra from "../layout/appExtra";
import {initializeIntercomService, updateIntercomService} from "../services/intercomService";
import {ToLeasingFormType} from "../types/leasingTypes";
import {createLeasingofTaxCase} from "../services/taxService";
import usePrevLocation from "../hooks/usePreviousLocation";
import {renderErrorMessage} from "../components/messages/errorMessage";
import {hasAdminRole} from "../utilities/hasAdminRole";
import {getRedirectParts} from "../utilities/urlFunctions";
import useIsEmbedded from "../hooks/useIsEmbedded";

const {Content} = Layout;
const DMF_PRO_ORIGIN = process.env.REACT_APP_DMF_PRO_ORIGIN
const AppInit = () => {
  const location = useLocation();
  const {user, fetchUserData} = UserStore.useContainer();
  const [intercomInitialized, setIntercomInitialized] = useState<boolean>(false);

  useInterval(fetchUserData, 2 * 60 * 1000);
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    if (user && !intercomInitialized) {
      setIntercomInitialized(true);
      initializeIntercomService(user);
    }
  }, [user])

  useEffect(() => {
    // Update Intercom Messenger Service every time the view/location changes.
    updateIntercomService()
  }, [location])

  return null;
}

const AuthorizedApp: FC = ({children}) => {
  const location = useLocation();
  const prevLocation = usePrevLocation(location);
  const history = useHistory()
  const isEmbedded = useIsEmbedded();
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  // This should be in notification store that would also cache it.
  const notify = (data: any) => {
    const {level, message, description, id} = data;
    const iconType = isNotificationLevel(level) ? level : "default";
    notifi[iconType]({
      key: id,
      message,
      description,
      placement: "topRight",
      top: 90,
      duration: 3,
    });
  };

  useEffect(() => {
    const socket = webSocket.create;
    webSocket.connectToNotification(socket, notify);
    return () => {
      webSocket.disconnect(socket);
    };
  }, []);

  useEffect(() => {
    if (!isEmbedded) {
      if (!hasAdminRole()) {
        const redirectParts = getRedirectParts(location.pathname);

        let href;
        if (redirectParts['context'] && redirectParts['value']) {
          href = `${DMF_PRO_ORIGIN}/${redirectParts['context']}/${redirectParts['value']}`;
        } else if (redirectParts['context']) {
          href = `${DMF_PRO_ORIGIN}/${redirectParts['context']}`;
        } else {
          href = `${DMF_PRO_ORIGIN}`;
        }

        window.location.href = href
      }
    }
  }, [location])

  useEffect(() => {

    setIsFullscreen(isFullscreenRoute(location.pathname));
    window.parent.postMessage({type: 'url-change', path: location.pathname, from: prevLocation.pathname}, "*");

  }, [location, prevLocation.pathname]);

  useEffect(() => {
    async function listenToMessageFromDMFPro(e: MessageEvent<{
      data: { toLeasing?: ToLeasingFormType & { taxCaseId?: string }, toImport?: { taxCaseId?: string } },
      actionType: unknown
    }>) {
      if (e.origin !== DMF_PRO_ORIGIN) {
        return;
      }
      if (e.data.actionType === 'copyTaxCaseToLeasingCase') {
        const toLeasing = e.data.data.toLeasing
        if (!toLeasing) return;
        try {
          const {data} = await createLeasingofTaxCase(Number(toLeasing.taxCaseId), {
            customerTypeId: toLeasing.customerTypeId,
            vehicleTypeId: toLeasing.vehicleTypeId
          })
          history.push({
            pathname: getPath("leasing", "new"),
            state: {from: {pathname: "tax"}, data},
          });
        } catch (err) {
          renderErrorMessage(err)
        }
      }
    }

    window.addEventListener('message', listenToMessageFromDMFPro)
    return () => {
      window.removeEventListener('message', listenToMessageFromDMFPro)
    }

  }, []);


  const pageClassName = location.pathname.split("/")[1] || "";

  return (
    <Layout className={`app-wrapper ${isEmbedded ? "is-embedded" : ""}`}>
      <AppSider/>
      <Layout className="site-layout">
        <AppHeader/>
        <Content className={`content${isFullscreen ? " content-fluid" : ""} ${pageClassName}`}>{children}</Content>
        <AppFooter/>
        <AppExtra/>
        <AppInit/>
      </Layout>
    </Layout>
  );
};

export default AuthorizedApp;
