import { Button, message, Space } from "antd";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../../../assets/icons/icon";
import ActivityDrawer from "../../../components/activityDrawer";
import Checklist from "../../../components/checklist/checklist";
import StatusTag from "../../../components/statusTag";
import LeasingStore from "../../../contexts/leasingStore";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import { cloneLeasingCase } from "../../../services/leasingService";
import { isAxiosError } from "../../../utilities/typeGuard";

const LeasingCaseHeadExtra: FC = () => {
  const { leasingCase, isAdmin } = LeasingStore.useContainer();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { partnerCase, id } = leasingCase;
  const { hasPermission } = UserStore.useContainer();
  const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false);
  const canSeeChecklist = hasPermission(partnerCase ? "partner_leasing.checklist" : "leasing.checklist");

  const handleCloneCase = async () => {
    try {
      setLoading(true);
      const { data } = await cloneLeasingCase(id);
      history.push({ pathname: getPath("leasing", "new"), state: { from: { pathname: "leasing" }, data } });
      setLoading(false);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  return (
    <Space>
      <StatusTag status={leasingCase.status} />
      {!!id && (
        <>
          <Button onClick={handleCloneCase} loading={loading}>
            {_t("clone_case")}
          </Button>
          {canSeeChecklist && <Checklist {...{ isAdmin, id, context: "leasing", btnText: _t("checklist") }} />}
          {isAdmin && leasingCase.links?.dealerView && (
            <Button href={leasingCase.links.dealerView} target="_blank" icon={<Icon name="open-outline"/>}>DMF PRO</Button>
          )}
          <Button onClick={() => setDrawerVisibility(true)}>{_t("activities", "note", " & ")}</Button>
          <ActivityDrawer
            visible={drawerVisibility}
            isAdmin={isAdmin}
            setVisibility={setDrawerVisibility}
            type="leasing"
            id={id}
          />
        </>
      )}
    </Space>
  );
};

export default LeasingCaseHeadExtra;
