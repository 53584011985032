import { Button, Col, Collapse, Divider, Form, FormInstance, Row, Space, } from "antd";
import { useForm } from "antd/es/form/Form";
import debounce from "lodash/debounce";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Prompt } from "react-router-dom";
import CollapseCard from "../../../../components/collapseCard";
import CustomCollapse from "../../../../components/customCollapse";
import LogText from "../../../../components/logText";
import SavedStatus from "../../../../components/savedStatus";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import { saveOffer } from "../../../../services/taxService";
import { OfferTax } from "../../../../types/taxTypes";
import consts from "../../../../utilities/consts";
import NewPriceFormItems from "./newPriceFormItems";
import NewPricePanelHeader from "./newPricePanelHeader";
import TaxablePriceInput from "./taxablePriceInput";
import TaxCollateralAmount from "./taxCollateralAmount";
import TaxDynamicFee from "./taxDynamicFee";
import TaxFee from "./taxFee";
import TaxLicensePlateFee from "./taxLicensePlateFee";
import TaxOfferActions from "./taxOfferActions";
import TaxOfferArchived from "./taxOfferArchived";
import TaxOfferCopy from "./taxOfferCopy";
import TaxOfferTotal from "./taxOfferTotal";
import TaxRegestrationFee from "./taxRegestrationFee";
import TaxRegestrationFeeOpt from "./taxRegestrationFeeOpt";
import TradePriceFormItems from "./tradePriceFormItems";
import TradePricePanelHeader from "./tradePricePanelHeader";
import TaxEstimatedCollateralAmount from "./taxEstimatedCollateralAmount";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import calculateNewPrice from '../calc/newPriceDecorator';
import { getDeprecitation, getDeviation } from "../calc/metaCalculators";

const { Panel } = Collapse;

interface TaxCaseOfferEditProps {
  optMode?: boolean;
}


const TaxOfferEdit: FC<TaxCaseOfferEditProps> = ({ optMode = false }) => {
  const { taxCase, setTaxCase, setCanSendInvoice } = TaxCaseStore.useContainer();
  const formWrapperRef = useRef<HTMLDivElement>(null)
  const [collapseKeys, setCollapseKeys] = useState<string[]>(optMode ? [] : ["1"]);
  const [loading, setLoading] = useState<boolean>(false);
  const [savedStatus, setSavedStatus] = useState<boolean>(true);
  const [offerForm] = useForm();

  const [deviation, setDeviation] = useState({
    newPriceWithoutEquipment: {
      ...getDeviation(
        taxCase.calculation?.newPrice?.newPriceWithoutEquipments || 0,
        taxCase.latestTaxRobotResult?.output?.newPrice.newPriceWithoutEquipment || 0,
        "NEW_PRICE"
      )
    },
    tradePrice: {
      ...getDeviation(
        taxCase.calculation?.tradePrice?.tradePrice || 0,
        taxCase.latestTaxRobotResult?.output?.tradePrice.result?.tradePrice || 0,
        "TRADE_PRICE"
      ),
    },
  })
  const [depreciation, setDeprecitation] = useState(getDeprecitation({
    firstRegDate: taxCase.vehicle?.firstRegDate || new Date().toISOString(),
    newPrice: taxCase.calculation?.newPrice?.newPrice || 0,
    tradePrice: taxCase.calculation?.tradePrice?.tradePrice || 0,
    mileage: taxCase.vehicle?.mileage || 0
  }))

  const key = optMode ? "internalCalculation" : "calculation";
  const { isNew } = taxCase.vehicle || {};
  const { fee } = taxCase.calculation || {};

  useEffect(() => {
    if (!optMode) {
      const currentFee = offerForm.getFieldValue("fee");

      if (currentFee !== fee) {
        offerForm.setFieldsValue({ fee });
      }
    }
  }, [fee, optMode]);

  useEffect(() => {
    if (formWrapperRef.current) {
      if (collapseKeys.slice(-1).pop() === '1') {
        const newPriceWithoutEquipmentsInput = formWrapperRef.current.querySelector("#newPrice_newPriceWithoutEquipments") as HTMLInputElement
        setTimeout(() => newPriceWithoutEquipmentsInput?.focus(), 1)
      }

    }
  }, [collapseKeys]);



  const handleSave = async (values: OfferTax) => {
    try {
      setLoading(true);
      const { data } = await saveOffer(taxCase.id, values, optMode);
      setTaxCase(data);
      setSavedStatus(true);
      optMode && setCanSendInvoice(
        internalRegistrationFeeIsValid(
          data.internalCalculation?.registrationFee?.registrationFee
        )
      );
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = (changedValues: Partial<OfferTax>, values: OfferTax) => {
    if (changedValues.registrationFee?.registrationFee) {
      delayedSetMatched(
        changedValues.registrationFee.registrationFee,
        taxCase[key]?.registrationFee?.registrationFee
      );
    }
    else if (changedValues.total) {
      delayedSetMatched(
        changedValues.total,
        taxCase[key]?.total
      );
    }

    if (changedValues.newPrice?.newPriceWithoutEquipments) {
      const newPrice = changedValues.newPrice.newPriceWithoutEquipments;
      const robotNewPrice = taxCase.latestTaxRobotResult?.output?.newPrice.newPriceWithoutEquipment;
      if (robotNewPrice) {
        setDeviation(prev => ({
          ...prev,
          newPriceWithoutEquipment: {
            ...getDeviation(newPrice || 0, robotNewPrice || 0, "NEW_PRICE"),
          }
        }))
      }
    }

    if ((changedValues.newPrice || changedValues.tradePrice?.tradePrice) && values.newPrice) {
      setDeprecitation(getDeprecitation({
        firstRegDate: taxCase.vehicle?.firstRegDate || new Date().toISOString(),
        newPrice: calculateNewPrice(values.newPrice),
        tradePrice: values.tradePrice?.tradePrice || 0,
        mileage: taxCase.vehicle?.mileage || 0,
      }))
    }

    if (changedValues.tradePrice?.tradePrice) {
      const tradePrice = changedValues.tradePrice.tradePrice;
      const robotTradePrice = taxCase.latestTaxRobotResult?.output?.tradePrice.result?.tradePrice;
      if (robotTradePrice) {
        setDeviation(prev => ({
          ...prev,
          tradePrice: {
            ...getDeviation(tradePrice || 0, robotTradePrice || 0, "TRADE_PRICE"),
          }
        }))
      }
    }
  };

  const internalRegistrationFeeIsValid = (registrationFee: unknown) => {
    return typeof registrationFee === 'number' && registrationFee >= 0;
  }

  const delayedSetMatched = useCallback(
    debounce((newValue: unknown, savedValue: unknown) => {
      setSavedStatus(newValue === savedValue)
    }, 500),
    [setSavedStatus, setCanSendInvoice, savedStatus]
  );

  const inititalValues =
    { ...taxCase[key], optimization: optMode, } || undefined; // added optimizationMode to form data to easily grap it in sub sub children.
  return (

    <div ref={formWrapperRef}>

      <CollapseCard
        title={_t(optMode ? "evaluation" : "preassessment")}
        defaultOpen={!optMode || taxCase.flags.accepted}
        loading={loading}
      >
        <Prompt when={!savedStatus} message={_t("msg.confirm_leave")} />
        <Form
          {...consts.formItemProps}
          form={offerForm}
          initialValues={inititalValues}
          onValuesChange={handleFormChange}
        >
          {isNew ? (
            <TaxablePriceInput />
          ) : (
            <>
              <CustomCollapse
                activeKey={collapseKeys}
                setActiveKey={setCollapseKeys}
              >
                <Panel key="1" header={
                  <NewPricePanelHeader
                    isLargeDeviation={deviation.newPriceWithoutEquipment.isLargeDeviation}
                    deviationPercentage={deviation.newPriceWithoutEquipment.deviation}
                  />
                } forceRender>
                  <NewPriceFormItems
                    vin={taxCase.vehicle?.vin}
                    firstRegDate={taxCase.vehicle?.firstRegDate}
                  />
                </Panel>
                <Panel key="2" header={
                  <TradePricePanelHeader isLargeDeviation={deviation.tradePrice.isLargeDeviation}
                    deviationPercentage={deviation.tradePrice.deviation}
                    depreciation={depreciation}
                  />} forceRender>
                  <TradePriceFormItems />
                </Panel>
              </CustomCollapse>
            </>
          )}
          <div className={isNew ? "" : "ml-05"}>
            {optMode ? <TaxRegestrationFeeOpt /> : <TaxRegestrationFee />}
            {taxCase.calculationTypeId === 1 && !optMode && (
              <TaxEstimatedCollateralAmount />
            )}
            <TaxLicensePlateFee />
            {taxCase.flags.calculateDynamicFee && !optMode ? (
              <TaxDynamicFee />
            ) : (
              <TaxFee />
            )}
            {taxCase.calculationTypeId === 1 && optMode && (
              <TaxCollateralAmount />
            )}
            <Divider className="mt-05 mb-1" />
            <TaxOfferTotal optMode={optMode} />
          </div>
          <Row className="pt-1" justify="space-between">
            <Col>
              <Space>
                <TaxOfferArchived optMode={optMode} />
                {optMode && <TaxOfferCopy />}
              </Space>
            </Col>
            <Col>
              <SavedStatus status={savedStatus} />
              <Form.Item shouldUpdate noStyle>
                {({ getFieldsValue }) => (
                  <Button
                    onClick={() => handleSave(getFieldsValue(true))}
                    type="primary"
                  >
                    {_t("save")}
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
          {!optMode && (
            <TaxOfferActions
              loading={loading}
              setLoading={setLoading}
              onSend={() => setCollapseKeys([])}
            />
          )}
        </Form>
      </CollapseCard>
      {!optMode && taxCase.log?.offerSent && (
        <LogText
          className="p-0 mt--1 mb-1 text-right"
          small
          logData={taxCase.log.offerSent}
          timeToSendOfferInMs={taxCase.timeToSendOfferInMs}
        />
      )}
    </div>
  );
};

export default TaxOfferEdit;
